import { stat } from "fs";
import ConversationTypes from "./conversation.types";

const INITIAL_STATE = {
  userTagsData: [],
  userMessages: [],
  chatters: [],
  totalMessages: 0,
  needReplyCount: 0,
  activeChatCount: 0,
  totalMessagesCount: null,
  uniqueChatsCount: null,
  totalMessagesRatio: null,
  uniqueChatsRatio: null,
  messages_overview: null,
  completeChattersList: null,
  chatterLoader: {
    total: 0,
    progress: 0
  },
  userChatters: [],
  chatIdsToFilter: null,
  chatterNextToken: null,
  currentWhatsappUsers: [],
  showLoader: true,
  userFollowupData: [],
  conversationAnalytics: [],
  dateWiseConversationAnalytics: [],
  dateWiseNeedsReplyAwaitingReply: [],
  chattersLastFetchedTime: null,
  chatterMessagesObject: {},
  activeChatterMessages: [],
  activeChatterTotalMessages: [],
  activeChatterPage: 0,
  activeChatter: null,
  activeChatterMessagesLoader: false,
  activeTotalPages: 0,
  activeNavbarLabel: null,
  activeTeamId: null,
};

const conversationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ConversationTypes.GET_USER_TAGS_SUCCESS:
      return {
        ...state,
        userTagsData: action.payload,
      };

    case ConversationTypes.GET_ALL_USER_MESSAGES_SUCCESS:
      return {
        ...state,
        userMessages: action.payload,
      };

    case ConversationTypes.GET_ALL_CHATTER_SUCCESS:
      return {
        ...state,
        chatters: action.payload,
      };

    case ConversationTypes.UPDATE_TOTAL_MESSAGES_COUNT:
      return {
        ...state,
        totalMessages: action.payload,
      };

    case ConversationTypes.UPDATE_NEED_REPLY_COUNT:
      return {
        ...state,
        needReplyCount: action.payload,
      };

    case ConversationTypes.UPDATE_ACTIVE_CHAT_COUNT:
      return {
        ...state,
        activeChatCount: action.payload,
      };

    case ConversationTypes.TOTAL_MESSAGE_COUNT:
      return {
        ...state,
        totalMessagesCount: action.payload,
      };
    case ConversationTypes.UNIQUE_CHAT_COUNT:
      return {
        ...state,
        uniqueChatsCount: action.payload,
      };
    case ConversationTypes.TOTAL_MESSAGE_RATIO:
      return {
        ...state,
        totalMessagesRatio: action.payload,
      };

    case ConversationTypes.UNIQUE_CHAT_RATIO:
      return {
        ...state,
        uniqueChatsRatio: action.payload,
      };

    case ConversationTypes.MESSAGES_OVERVIEW:
      return {
        ...state,
        messages_overview: action.payload,
      };

    case ConversationTypes.UPDATE_ACTIVE_CHATTER:
      return {
        ...state,
        activeChatter: action.payload,
        activeChatterMessagesLoader: true
      }

    case ConversationTypes.FILTER_CHATS_FOR_ACTIVE_CHAT:
      let activeChattersList = state.completeChattersList ? [ ...state.completeChattersList] : [];
      if (action.numbers === 0) {
        activeChattersList = [];
      } else if (action.numbers?.length) {
        activeChattersList = activeChattersList.filter(
          (it) => {
            if (action.numbers.indexOf(it.CreatedByUser) !== -1) {
              return it;
            } else {
              return false;
            }
          }
        );
      }
      return {
        ...state,
        userChatters: activeChattersList,
      };

    case ConversationTypes.FILTER_CHATS_ON_SEARCH:
      let chatterList = [...state.completeChattersList];
      if (action.value) {
        chatterList = chatterList.filter((it) =>
          it.Name?.toLowerCase().includes(action.value)
        );
      }

      return {
        ...state,
        userChatters: chatterList,
      };

    case ConversationTypes.GET_ALL_CHATTER_USING_GRAPHQL_START:
      return {
        ...state,
        chatterLoader: {
          total: 0,
          progress: 0
        }
      }

    case ConversationTypes.GET_ALL_CHATTER_USING_GRAPHQL_UPDATE:
      return {
        ...state,
        chatterLoader: {
          total: action.total,
          progress: action.progress ? action.progress  : 0,
        }
      }

    case ConversationTypes.GET_ALL_CHATTER_USING_GRAPHQL_SUCCESS:
      return {
        ...state,
        userChatters: action.payload,
        completeChattersList: action.payload,
        chatterNextToken: action.nextToken,
        chatterMessagesObject: {},
        activeChatterMessages:[],
        activeChatter: null,
        activeTeamId: action.teamId || null,
      };

      case ConversationTypes.GET_NEXT_CHATTER_USING_GRAPHQL_SUCCESS:
        return {
          ...state,
          userChatters: state.userChatters.concat(action.payload),
          completeChattersList: state.completeChattersList.concat(action.payload),
          chatterNextToken: action.nextToken,
        };

    case ConversationTypes.UPDATE_CHATTER_SUCCESS:
      return {
        ...state,
        userChatters: action.payload,
      };
    case ConversationTypes.GET_USERS_USING_GRAPHQL_SUCCESS:
      return {
        ...state,
        currentWhatsappUsers: action.payload,
      };

    case ConversationTypes.CLEAR_CHATTER_TOKEN:
      return {
        ...state,
        chatterNextToken: null,
      };
    case ConversationTypes.UPDATE_CONVERSATIONS_LOADER:
      return {
        ...state,
        showLoader: action.payload,
      };
    case ConversationTypes.GET_USER_FOLLOWUP_DATA_SUCCESS:
      return {
        ...state,
        userFollowupData: action.payload,
      };
    case ConversationTypes.GET_CONVERSATION_ANALYTICS_SUCCESS:
      return {
        ...state,
        conversationAnalytics: action.payload,
      };
    case ConversationTypes.GET_DATE_CONVERSATION_ANALYTICS_SUCCESS:
      return {
        ...state,
        dateWiseConversationAnalytics: action.payload,
      };

    case ConversationTypes.GET_DATE_NEEDS_REPLY_AWAITING_REPLY_SUCCESS:
      return {
        ...state,
        dateWiseNeedsReplyAwaitingReply: action.payload,
      };

    case ConversationTypes.CHATTERS_LAST_FETCHED_TIME:
      return {
        ...state,
        chattersLastFetchedTime: action.payload,
      };


    case ConversationTypes.GET_MESSAGES_FOR_ACTIVE_CHATTER:

      if(action.update) { // only incase of next page fetch  from server.

        return {
          ...state,
          ...(state.activeChatter === action.createdByUserAndNumber ? {
            activeChatterMessages: state.activeChatterMessages.concat(action.payload.slice(-20)) ,
            activeChatterTotalMessages: state.activeChatterTotalMessages.concat(action.payload),
            activeChatterPage: state.activeChatterPage + 1
          } : {}),
          
          ...(state.activeChatter === action.createdByUserAndNumber ? {activeChatterMessagesLoader: false } : {}),
        } 

      } else {
        return {
          ...state,
          ...(!action.createdByUserAndNumber  ? { // does not already exists in local
            activeChatterMessages: action.payload.slice(-20) ,
            activeChatterTotalMessages: action.payload,
            activeChatterPage: 1,
            activeTotalPages: action.total
            } : {}),
  
          ...(!action.createdByUserAndNumber ? {activeChatterMessagesLoader: false } : {}),
  
          ...(state.activeChatter === action.createdByUserAndNumber ? {
            activeChatterMessages: action.payload.slice(-20) ,
            activeChatterTotalMessages: action.payload,
            activeChatterPage:  1,
            activeTotalPages: action.total ? action.total : state.chatterMessagesObject[action.createdByUserAndNumber] ? state.chatterMessagesObject[action.createdByUserAndNumber].activeTotalPages: 0
          } : {}),
          
          ...(state.activeChatter === action.createdByUserAndNumber ? {activeChatterMessagesLoader: false } : {}),
        } 
      }

      
    
      case ConversationTypes.GET_NEXT_PAGE_MESSAGES_FOR_ACTIVE_CHATTER:
        return {
          ...state,
          activeChatterMessages: state.activeChatterTotalMessages.slice(-(action.page) * 20),
          activeChatterPage: action.page
        }

    case ConversationTypes.UPDATE_MESSAGES_FOR_CHATTER:
      return {
        ...state,
        chatterMessagesObject: {
          ...state.chatterMessagesObject,
          [action.chatId]: {
            ...(action.update ? {messages: state.activeChatterTotalMessages} : {messages: action.payload}) ,
            page: action.page ?  action.page : 0,
            activeTotalPages: action.totalPages ? action.totalPages : state.activeTotalPages ? state.activeTotalPages : 0
          }
        },
        activeChatterMessagesLoader: false
      }

    case ConversationTypes.SET_LABEL_CHATTERS:
      return {
        ...state,
        chatIdsToFilter: action.chatIdsToFilter
      }

    case ConversationTypes.ACTIVE_LABEL:
      return {
        ...state,
        activeNavbarLabel: action.payload
      }


    default:
      return {
        ...state,
      };
  }
};

export default conversationReducer;
