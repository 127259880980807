import { differenceInDays, differenceInMilliseconds } from "date-fns";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectOrgCreditHistory } from "../../redux/auth/auth.selector";
import { getFormattedDDMMYY } from "../../util/commonfunctions";
import TrialEndedStickyTopComp from "../Popups/TrialEndedStickyTopComp/TrialEndedStickyTopComp";
import Header from "./Header";
import Navbar from "./Navbar";
import { selectUserCreditHistory } from "../../redux/organization/organization.selector";

const HorizontalLayout = (props) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [content, setContent] = useState("Your 7-day trial has ended");

  function handleClose() {
    setOpenPopup(false)
  }

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  useEffect(() => {
    // if (props.fetchedUserCreditHistoryData) {
    //   if (props.fetchedUserCreditHistoryData.data.expiry_date && props.fetchedUserCreditHistoryData.data.plan_id === 1) {
    //     const monthsDifference = getMonthDifference(new Date(getFormattedDDMMYY(Date.now())), new Date(getFormattedDDMMYY(props.fetchedUserCreditHistoryData.data.expiry_date)));
    //     const date = new Date();
  
    //     console.log(monthsDifference)
  
    //     if (monthsDifference === 0 && getFormattedDDMMYY(props.fetchedUserCreditHistoryData.data.expiry_date).split(" ")[0] < date.getDate()) {
    //       setOpenPopup(true);
    //     } else if (monthsDifference < 0) {
    //       setOpenPopup(true)
    //     }
    //   }
    // } else 
    if (props.fetchedOrgCreditHistory) {
      if (props.fetchedOrgCreditHistory?.data?.validity) {
        const monthsDifference = getMonthDifference(new Date(getFormattedDDMMYY(Date.now())), new Date(getFormattedDDMMYY(props.fetchedOrgCreditHistory.data.validity)));
        const date = new Date();

        const millisecondsInADay = 24 * 60 * 60 * 1000;
        const validityDate = new Date(props.fetchedOrgCreditHistory.data.validity);
        const currentDate = new Date();

        if (props.fetchedOrgCreditHistory.data.is_trial === 1) {
          setOpenPopup(true);
          // const daysLeft = differenceInDays(new Date(props.fetchedOrgCreditHistory.data.validity), new Date())
          const daysLeft = Math.ceil(differenceInMilliseconds(validityDate, currentDate) / millisecondsInADay);

          if (daysLeft >= 0) {
            setContent(`Your free trial will be expired in ${daysLeft} days`); 
          } else {
            setContent("Your free trial has ended");
          }
        } else {
          setContent("Your plan has been expired. Renew it by clicking on upgrade button.");
        }
  
        if (monthsDifference === 0 && getFormattedDDMMYY(props.fetchedOrgCreditHistory.data.validity).split(" ")[0] < date.getDate() && localStorage.getItem("currentOrgId")) {
          setOpenPopup(true);
        } else if (monthsDifference < 0 && localStorage.getItem("currentOrgId")) {
          setOpenPopup(true)
        }
      }
    }
  }, [props.fetchedOrgCreditHistory, props.fetchedUserCreditHistoryData])

  console.log("Horizontal Layout rendered")

  return (
    <>
    {!window.location.pathname.startsWith('/settings/billing') ? <TrialEndedStickyTopComp openPopup={openPopup} 
    handleClose={handleClose} 
    content={content} 
    userPlanDetails = {props.fetchedUserCreditHistoryData}/> : null
    }
    <Navbar />
      {window.location.pathname !== "/conversations" &&
      window.location.pathname !== "/integrations" &&
      window.location.pathname !== "/leads" &&
      !window.location.pathname.startsWith('/organization')  &&
      !window.location.pathname.startsWith('/settings') ? (
      // !window.location.pathname.startsWith('/quick-replies')  ? (
      // !window.location.pathname == '/quick-replies'  ? (
        
        <Header
          theme={() => {}}
          openPopup={openPopup}
          content={content}
          handleClose={handleClose}
        />
      ) : null}
      
      <div className="main-content ht-100vh" >{props.children}</div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  fetchedUserCreditHistoryData: selectUserCreditHistory,
  fetchedOrgCreditHistory: selectOrgCreditHistory
});

export default connect(mapStateToProps)(React.memo(HorizontalLayout));
