
const AuthTypes = {
    LOGIN_START: 'LOGIN_START',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    
    CHECK_USER_EXISTENCE_START: "check_user_existence_start",
    CHECK_USER_EXISTENCE_SUCCESS: "check_user_existence_success",
    CHECK_USER_EXISTENCE_FAILURE: "check_user_existence_failure",

    USER_CREDIT_HISTORY_START: "user_credit_history_start",
    USER_CREDIT_HISTORY_SUCCESS: "user_credit_history_success",
    USER_CREDIT_HISTORY_FAILURE: "user_credit_history_failure",

    ORG_CREDIT_HISTORY_START: "org_credit_history_start",
    ORG_CREDIT_HISTORY_SUCCESS: "org_credit_history_success",
    ORG_CREDIT_HISTORY_FAILURE: "org_credit_history_failure",

    GET_PLAN_LIST_START: "get_plan_list_start",
    GET_PLAN_LIST_SUCCESS: "get_plan_list_success",
    GET_PLAN_LIST_FAILURE: "get_plan_list_failure",

    GET_USER_PROFILE_DATA_START: "get_user_profile_data_start",
    GET_USER_PROFILE_DATA_SUCCESS: "get_user_profile_data_success",
    GET_USER_PROFILE_DATA_FAILURE: "get_user_profile_data_failure",

    GET_TEAM_ADDON_LIST_START: "get_team_addon_list_start",
    GET_TEAM_ADDON_LIST_SUCCESS: "get_team_addon_list_success",
    GET_TEAM_ADDON_LIST_FAILURE: "get_team_addon_list_failure",

    GET_USER_TAGS_START: "get_user_tags_start",
    GET_USER_TAGS_SUCCESS: "get_user_tags_success",
    GET_USER_TAGS_FAILURE: "get_user_tags_failure",

    GET_USER_DATA_V2_START: "GET_USER_DATA_V2_START",
    GET_USER_DATA_V2_SUCCESS: "GET_USER_DATA_V2_SUCCESS",
    GET_USER_DATA_V2_FAILURE: "GET_USER_DATA_V2_FAILURE",

    GET_USER_ACCESS_TOKEN_SUCCESS : "GET_USER_ACCESS_TOKEN_SUCCESS",

    LOGOUT: "LOGOUT",
}

export default AuthTypes;