import React, { lazy } from 'react';
import Integrations from '../pages/Integrations';
import AdminPanelSecure from '../pages/EazybeAdminPanelSecure';


// Lazy-loaded components
const Organization = lazy(() => import("../pages/organization"));
// const Integrations = lazy(() => import("../pages/Integrations/index"));
const CRM = lazy(() => import("../pages/crm"));
const Settings = lazy(() => import("../pages/settings"));
const QuickReplies = lazy(() => import("../pages/quickreplies"));
const conversationAnalytics = lazy(() => import("../pages/conversation-analytics"));
const QuickRepliesAnalysis = lazy(() => import("../pages/quickreplies/analysis/QuickRepliesAnalysis"));
const Labels = lazy(() => import("../pages/labels"));
const UserProperties = lazy(() => import("../pages/userproperties"));
const conversation = lazy(() => import("../pages/conversation"));
const LeadCRM = lazy(() => import("../pages/leadCRM"));
const FailedTransaction = lazy(() => import("../pages/failedTransaction"));
const EazybeAdminPanel = lazy(() => import("../pages/EazybeAdminPanel"));

export  const publicRoutes = [
    { path: "/organization", component: Organization },
    { path: "/organization/:id", component: Organization },
    { path: "/conversations/analytics", component: conversationAnalytics, main: "Dashboard", section: [] },
    { path: "/team-inbox", component: conversation, main: "Team Inbox" },
    { path: "/integrations", component: Integrations },
    { path: "/leads", component: CRM },
    { path: "/settings", component: Settings },
    { path: "/settings/:id", component: Settings },
    { path: "/quick-replies", component: QuickReplies, main: "Manage Tools", section: [{ title: "Quick Replies", path: "/quick-replies" }, { title: "Labels", path: "/labels" }, { title: "User Profile", path: "/userproperties" }] },
    { path: "/quick-replies/analytics", component: QuickRepliesAnalysis, main: "Manage Tools", section: [{ title: "Quick Replies" }, { title: "Labels" }, { title: "User Profile" }] },
    { path: "/userproperties", component: UserProperties, main: "Manage Tools", section: [{ title: "Quick Replies", path: "/quick-replies" }, { title: "Labels", path: "/labels" }, { title: "User Profile", path: "/userproperties" }] },
    { path: "/labels", component: Labels, main: "Manage Tools", section: [{ title: "Quick Replies", path: "/quick-replies" }, { title: "Labels", path: "/labels" }, { title: "User Profile", path: "/userproperties" }] },
    { path: "/leadcrm", component: LeadCRM, main: "Lead CRM", section: [] },
    { path: "/eazybe/admin/panel", component: EazybeAdminPanel, main: "Eazybe Admin Panel", section: [] },
    { path: "/failedtransactions", component: FailedTransaction, main: "Failed Transactions", section: [] },
    { path: "/admin/panel/secure", component: AdminPanelSecure, main: "Eazybe Admin Panel", section: [] },

    // Uncomment the below routes if needed
    // { path: "/dashboard", component: dashboardMain, section: "Calls" ,subSection: "Analytics"},
    // { path: "/dashboard/teams", component: dashboardMain, section: "Calls" ,subSection: "Analytics"},
    // { path: "/dashboard/analysis", component: Analysis, section: "Calls", subSection: "Call Logs"},
    // Have to undo after calls sections fix.
    // { path: "/dashboard", component: dashboardMain, main: "Analytics", section: [{ title: "Whatsapp", path: "/conversations/analytics" }, { title: "Calls", path: "/dashboard" }]},
    // { path: "/dashboard/teams", component: dashboardMain, main: "Analytics", section: [{ title: "Calls" }]},
    // { path: "/dashboard/analysis", component: Analysis, main: "Analytics", section: [{ title: "Calls" }]},
    // { path: "/organization/employees", component: employees, section: "Organisation", subSection: "Employees"},
    // { path: "/organization/teams", component: teams },
    // { path: "/conversations/analytics", component: conversationAnalytics, section: "Conversations" ,subSection: "Analytics"},
    // This one too
    // { path: "/conversations/analytics", component: conversationAnalytics, main: "Analytics", section: [{ title: "Whatsapp", path: "/conversations/analytics" }, { title: "Calls", path: "/dashboard" }]}
    // { path: "/popups", component: Popups },
];

export default  publicRoutes;
