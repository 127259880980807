import OrganizationTypes from "./organization.types";
const INITIAL_STATE = {
  organizationData: [],
  createdOrganizationData: [],
  teamsList: [],
    filteredCallLogs: [],
    filteredEmployeeCallLogs: [],
    totalsOfCallLogs: [],
    totalsOfEmployeeCallLogs: [],
    callsOverViewList: [],
    organizationEmployeesList: [],
    usePlanList : [],
    userCreditHistory: [],
    employeesListCustom: [],
    currentActiveSeats: 0,
    currentSubscribedSeats: 0,
    userOrgList: null,
    userIntegrations: {},
    integrationTypes : {
      "hubspotIntegrations": 1,
      "freshdeskIntegrations": 2,
      "bitrixIntegrations": 3,
      "zohoIntegrations": 4,
      "googlesheetIntegrations": 5,
      "salesforceIntegrations": 6,
      "leadsquaredIntegrations": 7,
      "webhookIntegrations": 8,
  },
  quickRepliesAnalytics : null,
  conversationAnalytics : null,
  orgSchedulerAnalytics : "orgSchedulerAnalytics",
  orgEmployeesIntegrations : [],
  dontShowUpgrade : false,
  userProfileData: null
};



const organizationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrganizationTypes.GET_ORG_CONVERSATION_ANALYTICS_SUCCESS:
      return {
        ...state,
        conversationAnalytics: action.payload,
      };
    case OrganizationTypes.GET_ORGANIZATION_DATA_SUCCESS:
      return {
        ...state, //remove ifdoesnt work
        organizationData: action.payload,
      };

    case OrganizationTypes.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        createdOrganizationData: action.payload,
      };

    case OrganizationTypes.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        createdTeamData: action.payload,
      };
    case OrganizationTypes.GET_COLOR_PALETTE_SUCCESS:
      return {
        ...state,
        colorPaletteData: action.payload,
      };
    case OrganizationTypes.GET_ORG_TEAMS_SUCCESS:
      return {
        ...state,
        orgTeamsData: action.payload,
      };
    case OrganizationTypes.UPDATE_TEAM_DETAILS_SUCCESS:
      return {
        ...state,
        updatedTeamData: action.payload,
      };
    case OrganizationTypes.GET_USER_ROLES_SUCCESS:
      return {
        ...state,
        userRolesData: action.payload,
      };
    case OrganizationTypes.ADD_EMPLOYEES_SUCCESS:
      return {
        ...state,
        addedEmployeesData: action.payload,
      };
    case OrganizationTypes.GET_ORG_EMPLOYEES_SUCCESS:
      return {
        ...state,
        orgEmployeesData: action.payload,
      };
    case OrganizationTypes.GET_CUSTOM_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeesListCustom: action.payload,
      };

    case OrganizationTypes.FETCH_USER_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        userIntegrations: action.payload
      }

      case OrganizationTypes.GET_ORG_QUICK_REPLIES_ANALYTICS_SUCCESS:
      return {
        ...state,
        quickRepliesAnalytics: action.payload
      }

    case OrganizationTypes.GET_ORG_INVITED_EMPLOYEES_SUCCESS:
      return {
        ...state,
        orgInvitedEmployeesData: action.payload,
      };

      case OrganizationTypes.CHECK_USER_EXISTENCE_SUCCESS:
      return {
        ...state,
        userExistenceData: action.payload,
      };
      case OrganizationTypes.UPDATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        updateOrganisationData: action.payload,
      };
      case OrganizationTypes.REMOVE_USER_SUCCESS:
      return {
        ...state,
        removedUserData: action.payload,
      };

      case OrganizationTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updatedUserData: action.payload,
      };

      case OrganizationTypes.GET_TEAMS_LIST_SUCCESS:
        return {
            ...state,
            teamsList: action.payload
        }
        
        case OrganizationTypes.GET_CALL_LOGS_SUCCESS:
            if(!action.page || action.page === 0) {
                return {
                    ...state,
                    filteredCallLogs: action.payload,
                    totalsOfCallLogs: action.totals
                }
            } else {
                return {
                    ...state,
                    filteredCallLogs: state.filteredCallLogs.concat(action.payload),
                }
            }
        
        case OrganizationTypes.GET_EMPLOYEE_CALL_LOGS_SUCCESS:
            if(!action.page || action.page === 0) {
            return{
                ...state,
                filteredEmployeeCallLogs: action.payload,
                totalsOfEmployeeCallLogs: action.totals
            }
        } else {
            return{
                ...state,
                filteredEmployeeCallLogs: state.filteredEmployeeCallLogs.concat(action.payload)
            }
        }

        case OrganizationTypes.GET_EMPLOYEE_CALL_LOGS_RESET:
            return {
                ...state,
                filteredEmployeeCallLogs: []
            }
            

 case OrganizationTypes.GET_ORG_EMPLOYEES_LIST_SUCCESS:
            return {
                ...state,
                organizationEmployeesList: action.payload,
            }
 case OrganizationTypes.GET_USER_PLAN_LIST_SUCCESS:
            return {
                ...state,
                userPlanList: action.payload,
            }
 case OrganizationTypes.GET_USER_CREDIT_HISTORY_SUCCESS:
            return {
                ...state,
                userCreditHistory: action.payload,
            }
 case OrganizationTypes.GET_CURRENT_ACTIVE_SEATS_SUCCESS:
            return {
                ...state,
                currentActiveSeats: action.payload,
            }

 case OrganizationTypes.GET_CURRENT_SUBSCRIBED_SEATS_SUCCESS:
            return {
                ...state,
                currentSubscribedSeats: action.payload,
            }

  case OrganizationTypes.GET_USER_ALL_ORGANIZATIONS_SUCCESS:
            return {
              ...state,
              userOrgList: action.payload,
            }
  case OrganizationTypes.GET_ORG_SCHEDULER_ANALYTICS_SUCCESS:
            return {
              ...state,
              orgSchedulerAnalytics: action.payload,
            }
  case OrganizationTypes.GET_INTEGRATIONS_LAST_SYNC_SUCCESS:
            return {
              ...state,
              orgEmployeesIntegrations: action.payload,
            }
  case OrganizationTypes.SAVE_DONT_SHOW_UPGRADE:
            return {
              ...state,
              dontShowUpgrade: action.payload,
            }

    case OrganizationTypes.GET_ALL_USER_PROFILE_DATA:
      return {
        ...state,
        userProfileData: action.payload
      }


    default:
      return {
        ...state,
      };
  }
};

export default organizationReducer;


