import AuthTypes from "./auth.types";

const INITIAL_STATE = {
    userData: null,
    isAuth: false,
    userExistenceData: null,
    userTags: [],
    userDataV2 : null,
    activeUserAccessToken : null,
}

const authReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case AuthTypes.LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.payload,
                isAuth: true
            }

        case AuthTypes.CHECK_USER_EXISTENCE_SUCCESS:
            return {
                ...state,
                userExistenceData: action.payload,
                isAuth: true
            };

        case AuthTypes.USER_CREDIT_HISTORY_SUCCESS:
            return {
                ...state,
                userCreditHistory: action.payload
            }

        case AuthTypes.ORG_CREDIT_HISTORY_SUCCESS:
            return {
                ...state,
                orgCreditHistory: action.payload
            }

        case AuthTypes.GET_PLAN_LIST_SUCCESS:
            return {
                ...state,
                getPlanList: action.payload
            }

        case AuthTypes.GET_USER_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                userProfileData: action.payload
            }

        case AuthTypes.GET_USER_TAGS_SUCCESS:
            return {
                ...state,
                userTags: action.payload
            }

        case AuthTypes.GET_TEAM_ADDON_LIST_SUCCESS:
            return {
                ...state,
                getTeamAddonList: action.payload
            }

        case AuthTypes.LOGOUT:
            return {
                userData: null,
                isAuth: false,
                userExistenceData: null
            }

        case AuthTypes.GET_USER_DATA_V2_SUCCESS:
            return {
               ...state,
               userDataV2 : action.payload
            }
        case AuthTypes.GET_USER_ACCESS_TOKEN_SUCCESS:
            return {
               ...state,
               activeUserAccessToken : action.payload
            }
        default:
            return state;
    }
}

export default authReducer;