import CRMTypes from "./crm.types";

const INITIAL_STATE = {
  followupData: [],
  orgTagsData: [],
  individualFollowupData: [],
  /// CRM Data 
  integrationsSyncInfo : null,
  ///Zoho Crm Data
  zohoLeadsFieldsData : null,
  zohoLeadStatusArr : null,
  zohoContactsFieldsData : null,
  zohoContactStatusArr : null,
  zohoDealFieldsData : null,
  zohoDealStatusArr : null,
  zohoOrgInfo : null,
  zohoCreatedContactsData : [],
  zohoCreatedLeadsData : [],
  /// Hubspot Crm Data
  hubspotContactsFieldsData : null,
  hubspotAccountDetails : null,
  hubspotOwnerId : null,
  hubspotDealsPipelineData : null,
  hubspotTicketsPipelineData : null,
  hubspotDeletedContactsData : [],
  hubspotCallsDispositionData : [],
  hubspotCallsDirectionData : [],
  

};

const crmReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CRMTypes.GET_FOLLOWUPS_SUCCESS:
      return {
        ...state,
        followupData: action.payload,
      };
 
    case CRMTypes.GET_ORG_TAGS_SUCCESS:
      return {
        ...state,
        orgTagsData: action.payload,
      };
    case CRMTypes.GET_FOLLOWUP_DETAIL_WITH_COMMENTS_SUCCESS:
      return {
        ...state,
        individualFollowupData: action.payload,
      };
    case CRMTypes.GET_ZOHO_LEADS_FIELD_DATA_SUCCESS:
      return {
        ...state,
        zohoLeadsFieldsData: action.payload,
      };
    case CRMTypes.GET_ZOHO_LEADS_STATUS_DATA_SUCCESS:
      return {
        ...state,
        zohoLeadStatusArr: action.payload,
      };
    case CRMTypes.GET_ZOHO_CONTACTS_FIELD_DATA_SUCCESS:
      return {
        ...state,
        zohoContactsFieldsData: action.payload,
      };

      case CRMTypes.GET_ZOHO_CONTACTS_STATUS_DATA_SUCCESS:
        return {
          ...state,
          zohoContactStatusArr: action.payload,
        };
      case CRMTypes.GET_ZOHO_DEAL_FIELD_DATA_SUCCESS:
        return {
          ...state,
          zohoDealFieldsData: action.payload,
        };
      case CRMTypes.GET_ZOHO_DEAL_STATUS_DATA_SUCCESS:
        return {
          ...state,
          zohoDealStatusArr: action.payload,
        };
      case CRMTypes.GET_ZOHO_ORGANIZATION_INFO_SUCCESS:
        return {
          ...state,
          zohoOrgInfo: action.payload,
        };
      case CRMTypes.SAVE_ZOHO_CREATED_CONTACT_DATA_SUCCESS:
        return {
          ...state,
          zohoCreatedContactsData: action.payload,
        };
      case CRMTypes.SAVE_ZOHO_CREATED_LEAD_DATA_SUCCESS:
        return {
          ...state,
          zohoCreatedLeadsData: action.payload,
        };
        case CRMTypes.GET_HUBSPOT_CONTACTS_FIELD_DATA_SUCCESS:
          return {
            ...state,
            hubspotContactsFieldsData: action.payload,
          };
        case CRMTypes.GET_HUBSPOT_ACCOUNT_DETAILS_SUCCESS:
          return {
            ...state,
            hubspotAccountDetails: action.payload,
          };
        case CRMTypes.GET_HUBSPOT_OWNER_ID_SUCCESS:
          return {
            ...state,
            hubspotOwnerId: action.payload,
          };
        case CRMTypes.GET_HUBSPOT_OWNERS_SUCCESS:
          return {
            ...state,
            hubspotOwners : action.payload,
          };
        case CRMTypes.GET_HUBSPOT_DEALS_PIPELINE_DATA_SUCCESS:
          return {
            ...state,
            hubspotDealsPipelineData : action.payload,
          };
        case CRMTypes.GET_HUBSPOT_TICKETS_PIPELINE_DATA_SUCCESS:
          return {
            ...state,
            hubspotTicketsPipelineData : action.payload,
          };
        case CRMTypes.SAVE_HUBSPOT_DELETED_CONTACT_DATA_SUCCESS:
          return {
            ...state,
            hubspotDeletedContactsData : action.payload,
          };
        case CRMTypes.GET_INTEGRATIONS_SYNC_INFO_SUCCESS:
          return {
            ...state,
            integrationsSyncInfo : action.payload,
          };
        case CRMTypes.GET_HUBSPOT_CALLS_DIRECTION_DATA_SUCCESS:
          return {
            ...state,
            hubspotCallsDirectionData : action.payload,
          };
        case CRMTypes.GET_HUBSPOT_CALLS_DISPOSITION_DATA_SUCCESS:
          return {
            ...state,
            hubspotCallsDispositionData : action.payload,
          };

    default:
      return {
        ...state,
      };
  }
};

export default crmReducer;
