import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import "./sidebarcontent.scss";
import SimpleBar from "simplebar-react";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  EyeIcon,
  SettingsIcon,
  UserProfileIcon,
  UpgradeToProLogo,
  UpgradeToProBtnIcon,
  AnalysisIcon,
  TeamInboxIcon,
  IntegrationsIcon
} from "./SideBarIcons";
import { checkForPermission } from "../../util/commonfunctions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { userCreditHistory, orgCreditHistory, getPlanList } from "../../redux/auth/auth.action";
import { selectUserCreditHistory } from "../../redux/auth/auth.selector";
import { useTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem("currentOrgId")) {
      props.getOrgCreditHistory(localStorage.getItem("currentOrgId"))
    } else {
      props.getUserCreditHistory(localStorage.getItem("workspaceId"))
    }
    props.fetchPlanList();
    let targetNode = document.getElementsByClassName("sidebar-enable")[0];
    console.log("targetNode", targetNode);
    console.log(
      "targetNode contains",
      targetNode.classList.contains("vertical-collpsed")
    );

    if (targetNode.classList.contains("vertical-collpsed")) {
      props.setSideNavExpanded(false);
    } else {
      props.setSideNavExpanded(true);
    }
  }, []);

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu-new">
          <ul className="sidebar-items d-flex-col" id="side-menu-new">
            <li className="sidebar-item">
              {props.sideNavExpanded ? (
                <NavLink
                  activeClassName="active-main-nav"
                  to="/conversations/analytics"
                  className="nav-org"
                  isActive={() => ['/dashboard', '/conversations/analytics', 'integrations'].includes(location.pathname)}
                >
                  <div className="sidebar-icon-container">
                    <AnalysisIcon className="sidebar-icon calls-icon" />
                  </div>
                  <span>{t("Dashboard.message")}</span>
                </NavLink>
              ) : (
                <NavLink
                  activeClassName="active-nav"
                  to="/conversations/analytics"
                  className="nav-org"
                  onClick={props.toggleSideNav}
                  isActive={() => ['/dashboard', '/conversations/analytics'].includes(location.pathname)}
                >
                  <div className="sidebar-icon-container">
                    <AnalysisIcon className="sidebar-icon calls-icon" />
                  </div>
                  <span className="sidebar-item-name">
                    {t("Dashboard.message")}
                  </span>
                </NavLink>
              )}
            </li>
           { checkForPermission(1) && <li className="sidebar-item">
              {props.sideNavExpanded ? (
                <NavLink
                  activeClassName="active-main-nav"
                  to="/team-inbox/"
                  className="nav-org"
                  isActive={() => ['/team-inbox/'].includes(location.pathname)}
                >
                  <div className="sidebar-icon-container">
                    <TeamInboxIcon className="sidebar-icon users-icon" />
                  </div>
                  <span>{t("Team_inbox.message")}</span>
                </NavLink>
              ) : (
                <NavLink
                  activeClassName="active-nav"
                  to="/team-inbox/"
                  className="nav-org"
                  onClick={props.toggleSideNav}
                  isActive={() => ['/team-inbox/'].includes(location.pathname)}
                >
                  <div className="sidebar-icon-container">
                    <TeamInboxIcon className="sidebar-icon users-icon" />
                  </div>
                  <span className="sidebar-item-name">
                    {t("Team_inbox.message")}
                  </span>
                </NavLink>
              )}
            </li>}
            {/* <li className="sidebar-item">
              {props.sideNavExpanded ? (
                <NavLink
                  activeClassName="active-main-nav"
                  to="/quick-replies"
                  className="nav-org"
                  isActive={() => ['/quick-replies', '/labels', '/userproperties'].includes(location.pathname)}
                >
                  <div className="sidebar-icon-container">
                    <SettingsIcon className="sidebar-icon" />
                  </div>
                  <span>{t("Manage_Tools.message")}</span>
                </NavLink>
              ) : (
                <NavLink
                  activeClassName="active-nav"
                  to="/quick-replies"
                  className="nav-org"
                  onClick={props.toggleSideNav}
                  isActive={() => ['/quick-replies', '/labels', '/userproperties'].includes(location.pathname)}
                >
                  <div className="sidebar-icon-container">
                    <SettingsIcon className="sidebar-icon" />
                  </div>
                  <span className="sidebar-item-name">
                    {t("Manage_Tools.message")}
                  </span>
                </NavLink>
              )}
            </li> */}

            {
              checkForPermission(1) &&
              <li className="sidebar-item nav-org-container d-flex-col">
                {props.sideNavExpanded ? (
                  <NavLink
                    activeClassName="active-main-nav"
                    className="nav-org"
                    to="/organization"
                  >
                    <div className="sidebar-icon-container">
                      <EyeIcon className="sidebar-icon" />
                    </div>

                    <span>{t("Organisation.message")}</span>
                  </NavLink>
                ) : (
                  <NavLink
                    activeClassName="active-nav"
                    to="/organization"
                    className="nav-org"
                    onClick={props.toggleSideNav}
                  >
                    <div className="sidebar-icon-container">
                      <EyeIcon className="sidebar-icon" />
                    </div>
                    <span className="sidebar-item-name">
                      {t("Organisation.message")}
                    </span>
                  </NavLink>
                )}
              </li>
            }

            <li className="sidebar-item nav-org-container d-flex-col">
              {props.sideNavExpanded ? (
                <NavLink
                  activeClassName="active-main-nav"
                  className="nav-org"
                  to="/integrations"
                >
                  <div className="sidebar-icon-container">
                    <IntegrationsIcon className="sidebar-icon" />
                  </div>

                  <span>{t("Integrations.message")}</span>
                </NavLink>
              ) : (
                <NavLink
                  activeClassName="active-nav"
                  to="/integrations"
                  className="nav-org"
                  onClick={props.toggleSideNav}
                >
                  <div className="sidebar-icon-container">
                    <IntegrationsIcon className="sidebar-icon" />
                  </div>
                  <span className="sidebar-item-name">
                    {t("Integrations.message")}
                  </span>
                </NavLink>
              )}
            </li>

            <li className="sidebar-item nav-settings-container d-flex-col">
              {props.sideNavExpanded ? (
                <NavLink
                  className="nav-settings"
                  to="/settings"
                  activeClassName="active-main-nav"
                >
                  <div className="sidebar-icon-container">
                    <UserProfileIcon className="sidebar-icon" />
                  </div>
                  <span>
                    {t("My_Account.message")}
                  </span>
                </NavLink>
              ) : (
                <NavLink
                  activeClassName="active-nav"
                  to="/settings"
                  className="nav-settings"
                  onClick={props.toggleSideNav}
                >
                  <div className="sidebar-icon-container">
                    <UserProfileIcon className="sidebar-icon" />
                  </div>
                  <span className="sidebar-item-name">
                    {t("My_Account.message")}
                  </span>
                </NavLink>
              )}
            </li>
            <li className="upgradeToPro">
              <div className="upgradeToProLogo">
                <UpgradeToProLogo />
              </div>
              <div className="upgradeToProContent">
                <Link to={"/settings/billing"}>
                  <div className="upgradeToProFooter">
                    <h6 style={{ marginRight: "5px" }}>
                      {t('Upgrade_now.message')}
                    </h6>
                    <UpgradeToProBtnIcon />
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
  userCreditHistory: selectUserCreditHistory
});

const mapDispatchToProps = (dispatch) => ({
  getOrgCreditHistory: (org_id) => dispatch(orgCreditHistory(org_id)),
  getUserCreditHistory: (workspace_id) => dispatch(userCreditHistory(workspace_id)),
  fetchPlanList: () => dispatch(getPlanList())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarContent));
