import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SidebarContent from "./SidebarContent";
import Logo from '../../assets/eazybeTransparentLogo.png'
import eazybeLogo from "../../assets/icons/eazybe-logo-white.png";
import './navbar.scss';
import TrialEndedStickyTopComp from "../Popups/TrialEndedStickyTopComp/TrialEndedStickyTopComp";
import { selectUserExistenceData } from "../../redux/auth/auth.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getFixLengthString } from "../../util/commonfunctions";

const Navbar = props => {

  const [orgLogo, setOrgLogo] = useState({
    show: false,
    img: "",
    name: ""
  })

  const [sideNavExpanded, setSideNavExpanded] = useState(false);

  const toggleSideNav = () => {
    let bodyEl = document.body;
    setSideNavExpanded(!sideNavExpanded);
    if (sideNavExpanded) {
      bodyEl.classList.add("vertical-collpsed");
    } else {
      bodyEl.classList.remove("vertical-collpsed");
    }
  };



  useEffect(() => {
    let orgCode = localStorage.getItem("currentOrgCode");
    // console.log("sidebar orgCode", orgCode)
    if (orgCode && orgCode != null) {
      fetchOrganizationData(orgCode);
    }
  }, []);

  const fetchOrganizationData = (orgCode) => {
    // console.log("sidebar fetching orgdata")
    fetch(`https://eazybe.com/api/v1/whatzapp/getOrganizationDetails?organization_code=${orgCode}`)
      .then((res) => {
        let errorMessage;
        if (res.status !== 200 && res.status !== 201) {
          errorMessage = "Something Went Worng!";
        }
        if (errorMessage) throw new Error(errorMessage);
        return res.json();
      })
      .then((resData) => {
        // setOrganizationData(resData);
        // console.log("sidebar orgData", resData)
        if (resData?.type === true) {
          // console.log("orgnaizationData", resData);
          setOrgLogo({
            show: true,
            img: resData.data[0].org_logo,
            name: resData.data[0].org_name
          })
        }
      })
      .catch((err) => {
        console.log(err);

      });
  }

  return (
    <React.Fragment>
      <div className="vertical-menu" onMouseEnter={toggleSideNav} onMouseLeave={toggleSideNav}>
        <div className="navbar-brand-box">
          <div className="orgInfo">
            <Link to="/" className="logo logo-dark">
              <span className="logo-lg">
                <img src={orgLogo.show && (orgLogo.img != null ? orgLogo.img : eazybeLogo)} alt="" height="30" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={Logo} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={Logo} alt="" height="19" />
              </span>
            </Link>
            <p className="org-name">{props.userExistence.data?.callyzer_user_mappings?.length ? (props.userExistence.data?.callyzer_user_mappings[0]?.is_default_org ? getFixLengthString(orgLogo.name, 10) : getFixLengthString(props.userExistence.data.name, 10)) : ""}</p>
          </div>
        </div>

        <div data-simplebar className="h-100">
          <SidebarContent
            sideNavExpanded={sideNavExpanded}
            setSideNavExpanded={setSideNavExpanded}
            toggleSideNav={toggleSideNav}
          />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = createStructuredSelector({
  userExistence: selectUserExistenceData,
})

export default connect(mapStateToProps)(React.memo(Navbar));