/*global chrome*/
import React, { useEffect, useState } from "react";
import "./hubspotintegrationpage.scss";
import HubspotNotConnected from "./HubspotNotConnected/HubspotNotConnected";
import HubspotPage1 from "./HubspotPage1/HubspotPage1";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { EXTENSION_ID_PRODUCTION } from "../../../../util/api";
import { saveDontShowUpgrade } from "../../../../redux/organization/organization.action";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { checkForCrmiKeyInAuth } from "../../../../util/helper";

let REFRESH_TOKEN = "8c82e429-0530-420a-bd83-c746af0c12d8";
let CLIENT_ID = "afc8d801-b77d-43db-a963-6a6993568749";
let CLIENT_SECRET = "46e6a98e-6072-4385-a481-0de345d6f5e3";
let EXTENSION_ID = "clgficggccelgifppbcaepjdkklfcefd";
let EXTENSION_ID_TEST = "fpnlneiiaeclcckbcoldjhcpaofgdmfl";
let REDIRECT_URI = "https://workspace.eazybe.com/integrations?section=hubspot";

const HubspotIntegrationPage = ({ setMainSectionIntegrations, connectIntegration, extensionIdToSendMessageTo , saveDontShowUpgradeStatus}) => {
  let HUBSPOT_SECTION_IDS = {
    ALREADY_CONNECTED: "ALREADY_CONNECTED",
    NOT_CONNECTED: "NOT_CONNECTED",
    GO_BACK_TO_EXTENSION: "GO_BACK_TO_EXTENSION",
    HUBSPOT_PAGE1: "HUBSPOT_PAGE1",
  };

  const [activeSectionHubspot, setActiveSectionHubspot] = useState(
    HUBSPOT_SECTION_IDS.NOT_CONNECTED
  );
  // const [activeSectionHubspot, setActiveSectionHubspot] = useState(HUBSPOT_SECTION_IDS.ALREADY_CONNECTED);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    console.log("queryParams in hubspot section", queryParams);
    let tempCode = queryParams?.get("code");
    console.log("tempCode : ", tempCode);
    if (tempCode) {
      getBearerToken(tempCode);
    } else {
      checkIfHubspotIntegrated();
    }

    /// Redirect to redirect_uri 
    if(connectIntegration) {
      window.open(redirectUri);
    }

  }, []);

  const [userAlreadyConnected, setUserAlreadyConnected] = useState(false);

  // let redirectUri =
  //   "https://app.hubspot.com/oauth/authorize?client_id=afc8d801-b77d-43db-a963-6a6993568749&redirect_uri=https://workspace.eazybe.com/integrations?section=hubspot&optional_scope=files.ui_hidden.read%20files%20tickets%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read&scope=crm.objects.contacts.read";

  // let redirectUri =
  //   "https://app.hubspot.com/oauth/authorize?client_id=afc8d801-b77d-43db-a963-6a6993568749&redirect_uri=https://workspace.eazybe.com/integrations?section=hubspot&scope=crm.objects.contacts.read%20crm.objects.owners.read";

  // const onClickconnectIntegration = () => {
  //   window.open(redirectUri);
  // };


  const clientId = 'afc8d801-b77d-43db-a963-6a6993568749';
const redirectUri = 'https://workspace.eazybe.com/integrations?section=hubspot';

const scopes = [
  'crm.objects.contacts.read',
];

const optionalScopes = [
  'files.ui_hidden.read',
  'files',
  'tickets',
  'crm.lists.read',
  'crm.objects.contacts.write',
  'crm.objects.companies.write',
  'crm.schemas.contacts.read',
  'crm.lists.write',
  'crm.objects.companies.read',
  'crm.objects.deals.read',
  'crm.objects.deals.write',
  'crm.schemas.companies.read',
  'crm.schemas.companies.write',
  'crm.schemas.contacts.write',
  'crm.schemas.deals.read',
  'crm.schemas.deals.write',
  'crm.objects.owners.read',
  'automation',
  'timeline',
  'analytics.behavioral_events.send',
  'crm.objects.custom.write',
  'crm.objects.custom.read',
  'crm.schemas.custom.read',
];




const buildAuthUrl = () => {
  const baseUrl = 'https://app.hubspot.com/oauth/authorize';
  const params = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: scopes.join(' '),
    optional_scope: optionalScopes.join(' ')
  });

  return `${baseUrl}?${params.toString()}`;
};

const onClickconnectIntegration = () => {
  const authUrl = buildAuthUrl();
  window.open(authUrl, '_blank', 'noopener,noreferrer');
};

  const getBearerToken = (authCode) => {
    console.log("getBearerToken called : ", authCode);
    fetch(
      `https://eazybe.com/api/v1/whatzapp/hubspotauthentication?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}&grant_type=authorization_code&code=${authCode}&redirect_uri=${REDIRECT_URI}`,
      {
        method: "post",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.result) {
          window.gtag("event", "Hubspotintegrated");
          window.gtagAW("event", "Hubspotintegrated");
          saveDontShowUpgradeStatus(true);
          sendMessageToChromeExtension(true, 500);
          setActiveSectionHubspot(HUBSPOT_SECTION_IDS.GO_BACK_TO_EXTENSION);
          checkForCrmiKeyInAuth();
          // window.open("https://web.whatsapp.com");
        } else {
          setActiveSectionHubspot(HUBSPOT_SECTION_IDS.NOT_CONNECTED);
        }
        console.log(data);
      });
  };

  const checkIfHubspotIntegrated = () => {
    fetch(
      `https://eazybe.com/api/v1/whatzapp/gethubspotauthtokens?workspace_id=${localStorage.getItem(
        "workspaceId"
      )}`
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("response : ", response);
        if (response.result) {
          // sendMessageToChromeExtension(true, 500);
          setActiveSectionHubspot(HUBSPOT_SECTION_IDS.ALREADY_CONNECTED);
        } else {
          // sendMessageToChromeExtension(false, 500);
          setActiveSectionHubspot(HUBSPOT_SECTION_IDS.NOT_CONNECTED);
        }
      });
  };

  const sendMessageToChromeExtension = (status, time = 10, id="fpnlneiiaeclcckbcoldjhcpaofgdmfl", key) => {
    setTimeout(() => {
      console.log("sendMessageToChromeExtension extensionIdToSendMessageTo", extensionIdToSendMessageTo);
      if (chrome?.runtime) {
        chrome.runtime.sendMessage(
          extensionIdToSendMessageTo   
          ,
          { key : key ?? "HUBSPOT_CONNECTED" },
          (response) => {
            console.log("response : ", response);
            if (!response?.success) {
            }
          }
        );

        chrome.runtime.sendMessage(
           EXTENSION_ID_PRODUCTION     
          ,
          { key : key ?? "HUBSPOT_CONNECTED" },
          (response) => {
            console.log("response : ", response);
            if (!response?.success) {
            }
          }
        );
      }
    }, time);
  };

  return (
    <div>
      <ArrowBackIcon
        onClick={setMainSectionIntegrations}
        className="mt-1 cursor-pointer"
      />
      {activeSectionHubspot == HUBSPOT_SECTION_IDS.ALREADY_CONNECTED && (
        <>
          <HubspotNotConnected sendMessageToChromeExtension={sendMessageToChromeExtension} connected={true} />
        </>
      )}

      {activeSectionHubspot == HUBSPOT_SECTION_IDS.NOT_CONNECTED && (
        <HubspotNotConnected  onClickConnect={onClickconnectIntegration} />
      )}
      {activeSectionHubspot == HUBSPOT_SECTION_IDS.HUBSPOT_PAGE1 && (
        <HubspotPage1 />
      )}

      {activeSectionHubspot == HUBSPOT_SECTION_IDS.GO_BACK_TO_EXTENSION && (
        <HubspotNotConnected sendMessageToChromeExtension={sendMessageToChromeExtension} connected={true} />
      )}
    </div>
  );
};


const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
  saveDontShowUpgradeStatus: (status) => dispatch(saveDontShowUpgrade(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HubspotIntegrationPage);